import React, { useState } from 'react';
import { Box } from '@heycater/design-system';
import Image from 'next/image';

import Banner2 from '@images/homepage/banner2.jpeg';
import Banner2Lowres from '@images/homepage/Banner2Lowres.jpeg';
import MobileBanner2 from '@images/homepage/MobileBanner2.jpeg';
import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';

export default function BannerImage1() {
  const mobile = useBreakpointDown('sm');
  const [loaded, setLoaded] = useState(false);
  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <Box>
      {mobile ? (
        <div style={{ position: 'relative', width: '100%', height: '201px' }}>
          <Image
            src={MobileBanner2}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            priority
            alt=""
          />
        </div>
      ) : (
        <Image
          src={loaded ? Banner2 : Banner2Lowres}
          quality={100}
          onLoadingComplete={handleLoad}
          placeholder="blur"
          objectFit="cover"
          alt=""
          blurDataURL={Banner2Lowres.src}
        />
      )}
    </Box>
  );
}
